<template>
  <v-container fluid>
    <!-- Section Path File/Modul -->
    <v-breadcrumbs :items="pathModul" divider="-" normal class="pathModul"></v-breadcrumbs>

    <!-- Section Filtering Input/Select/Button -->
    <v-container fluid :class="$vuetify.breakpoint.lgAndUp ? 'px-6 pt-0' : 'px-1'">
      <v-row align="center">
        <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'">
          <v-select @change="selSta(s_sel)" :items="stn_List" label="Station" prepend-inner-icon="mdi-store-marker"
            v-model="s_sel" hide-details solo dense></v-select>
        </v-col>

        <!-- Column Select Calendar -->
        <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'">
          <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
            min-width="auto" v-model="modalCalendar">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateFormat" label="Date" prepend-inner-icon="mdi-calendar-today" readonly
                v-bind="attrs" v-on="on" hide-details solo dense></v-text-field>
            </template>
            <v-date-picker @change="sel_Date(dateFormat)" v-model="dateFormat"
              @input="modalCalendar = false"></v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Generate Button -->
        <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'"> 
          <v-btn v-if="this.$store.getters.user.role == 5" class="white--text button mt-3" solo dense normal @click="
              GenerateTable();
              getParameterList();
            " :loading="loadingReport">
            GENERATE REPORT
          </v-btn>
          <v-btn v-else class="white--text button mt-3" solo dense normal @click="
              GenerateTable();
              getParameterList();
            " :loading="loadingReport" :disabled="valStation == null">
            GENERATE REPORT
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-show="showExportBtn" color="excel" class="mt-3" style="float: right" elevation="2" v-bind="attrs" v-on="on"
                @click="exportExcel()">
                <v-icon color="white" center dark> mdi-microsoft-excel </v-icon>
              </v-btn>
            </template>
            <span>Export to Excel</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-show="showExportBtn" color="csv" class="mt-3 mx-2" style="float: right" elevation="2" v-bind="attrs" v-on="on"
                @click="exportCSV()">
                <v-icon color="white" center dark> mdi-file-delimited </v-icon>
              </v-btn>
            </template>
            <span>Export to CSV</span>
          </v-tooltip>
        </v-col>

      </v-row>

      <v-card>
        <!-- Main Report Title/Tab -->
        <v-tabs>
          <v-tab>DAILY</v-tab>
        </v-tabs>

        <!-- Mini Title (Table Title) -->
        <v-row justify="center" class="pt-4">
          <v-col cols="12">

            <v-row justify="center" v-if="showTitle">
              <v-col cols="12" class="text-md-center">
                <h4 v-if="stationID == 'All Station'" class="pa-3">
                  Water Quality Status For {{ stationID }}
                  {{ convertDateFormat(dateFormat) }}
                </h4>
                <h4 v-else class="pa-3">
                  Water Quality Status For {{ locationStation }} ({{ stationID }})
                  {{ convertDateFormat(dateFormat) }}
                </h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-md-right">

                <!-- Button Back to Table -->
                <v-btn class="ma-2 white--text btn-generate button"
                  v-if="backbutton"
                  style="position: absolute; right: 8px; top: 45px; z-index: 1"  solo dense normal
                  @click="backToTable">
                  BACK
                  <v-icon right dark big> mdi-arrow-left-top-bold </v-icon>
                </v-btn>

                <v-btn class="ma-2 white--text" style="position: absolute; right: 8px; top: 45px"
                  v-if="chartbutton"
                  color="button" solo dense normal @click="generateChart"
                  :disabled="valStation == null || s_sel == 'All Station'">
                  CHART <v-icon right dark big>mdi-chart-line</v-icon>
                </v-btn>

              </v-col>
            </v-row>

          </v-col>
        </v-row>

        <!-- Form Table -->
        <div 
        v-if="showChart == false" class="px-4" 
        :style="$vuetify.breakpoint.lgAndUp? 'max-height: calc(100vh - 240px); overflow-y: auto; overflow-x: hidden;' : '' ">
          <!-- <v-data-table
          :headers="tableH"
          :items="tableCon"
          :header-props="{ sortIcon: null }"
          class="elevation-1 tableDailyReport"
          :items-per-page="10"
          no-select-on-click
          fixed-header
          :loading="this.loadingTable"
          :loading-text="this.loadingText"
          >
            <template v-slot:header>
                <thead v-if="inExportExcel? false : true" class="header-colspan">
                  <tr class="">
                    <th id="th_hub" class="th-hub" v-for="item in check_list" :key="item.value">
                      <input v-if="item.value != 'datetime_a' && item.value != 'station_a' && item.value != 'LOCATION'"
                      :value="item.value"
                      type="checkbox" 
                      v-model="arrayChecked" 
                      @change="checkedValue($event)">
                    </th>
                </tr>
                </thead>
            </template>

            <template v-slot:body>
              <tr v-for = "item in tableCon " :key="item.station_id"> 
                <td class="text-center v-data-table__divider" v-for = "header in tableH" :key="header" v-html = "item[header.value]">
                    
                </td>
              </tr>
            </template>
            

          </v-data-table> -->

          <v-data-table :headers="tableHeader" :items="contentDetail" class="elevation-1 tableDailyReport headerDesktop text-center"
            :items-per-page="itemPerPage" no-select-on-click fixed-header :loading="loadingTable"
            :loading-text="this.loadingText" :mobile-breakpoint="0" :class="{'datatable-mobile' : !$vuetify.breakpoint.lgAndUp}">
            <template v-slot:header>
              <thead v-if="inExportExcel ? false : true" class="header-colspan">
                <tr class="">
                  <th id="th_hub" class="th-hub" v-for="item in tableHeader" :key="item.value">
                    <input v-if="
                        item.value != 'DATETIME' &&
                        item.value != 'STATION_ID' &&
                        item.value != 'LOCATION'
                      " :value="item.value" type="checkbox" v-model="arrayChecked" @change="checkedValue($event)" />

                  </th>
                </tr>
              </thead>
            </template>

            <template v-slot:item="{ item }">
              <tr>
                <template v-for="header in tableHeader">
                  <td :key="header.text" v-if="
                      header.value == 'DATETIME' && item[header.value] != null
                    ">
                    {{
                    new Date(item[header.value]).toLocaleDateString("en-GB") +
                    " " +
                    new Date(item[header.value]).toLocaleTimeString()
                    }}
                  </td>
                  <td :key="header.text" v-else-if="
                      ( header.value == 'STATION_ID'  || header.value == 'LOCATION' )   && item[header.value] != null
                    ">
                    {{item[header.value]}}
                  </td>
                  <td :key="header.text" v-else-if="
                      (header.value == 'TSS' || header.value == 'TURBIDITY') &&
                      item[header.value] != null
                    ">
                    {{ Number(item[header.value]).toFixed(1) }}
                  </td>
                  <td :key="header.text" v-else-if="
                      (header.value == 'NH3' || header.value == 'PAH_HYDROCARBON' || header.value == 'OIL_IN_WATER') &&
                      item[header.value] != null
                    ">
                    {{ Number(item[header.value]).toFixed(4) }}
                  </td>
                  <td :key="header.text" v-else-if="item[header.value] != null">
                    {{ Number(item[header.value]).toFixed(2) }}
                  </td>
                  <td :key="header.text" v-else-if="
                      item[header.value] == null && header.value == 'DO_SAT'
                    " :style="item.DOSAT_FLAG != null ? 'color:red' : ''">
                    {{ item.DOSAT_FLAG == null ? "NA" : item.DOSAT_FLAG }}
                  </td>
                  <td :key="header.text" v-else-if="
                      item[header.value] == null && header.value == 'DO_CON'
                    " :style="item.DOCON_FLAG != null ? 'color:red' : ''">
                    {{ item.DOCON_FLAG == null ? "NA" : item.DOCON_FLAG }}
                  </td>
                  <td :key="header.text" v-else-if="
                      item[header.value] == null &&
                      header.value == 'TEMPERATURE'
                    " :style="item.TEMP_FLAG != null ? 'color:red' : ''">
                    {{ item.TEMP_FLAG == null ? "NA" : item.TEMP_FLAG }}
                  </td>
                  <td :key="header.text" :style="
                      item[header.value + '_FLAG'] != null ? 'color:red' : ''
                    " v-else>
                    {{
                    item[header.value + "_FLAG"] == null
                    ? "NA"
                    : item[header.value + "_FLAG"]
                    }}
                  </td>
                </template>
              </tr>
            </template>
          </v-data-table>
        </div>

        <!-- Section Result Generated Report (TABLE) -->
        <div v-if="showChart == true" class="px-0">
          <!-- Section Result Generated Report (CHART) -->
          <v-container class="container-result-generated pa-6" fluid style="
              max-height: calc(100vh - 385px);
              overflow-y: auto;
              overflow-x: hidden;
            ">
            <!-- Chart -->
            <highcharts :constructorType="'stockChart'" class="hc" :options="chartOptions" ref="chart">
            </highcharts>
          </v-container>
        </div>

        <template>
          <v-footer padless>
            <v-col class="text-center footer-report" cols="12">
              <span class="one-text-footer mx-5">
                <span class="red--text">C</span>
                <span class="blue--text">=CALIBRATION</span>
              </span>
              <span class="one-text-footer mx-5">
                <span class="red--text">M</span>
                <span class="blue--text">=MAINTENANCE</span>
              </span>
              <!--<span class="one-text-footer mx-5 dontBreak">
                  <span class="red--text">PRBC</span>
                  <span class="blue--text">=POWER FAILURE</span>
                </span> -->
              <span class="one-text-footer mx-5 dontBreak">
                <span class="red--text">DOR</span>
                <span class="blue--text">=DATA OUT OF SENSOR RANGE</span> </span><br />
              <span class="one-text-footer mx-5">
                <span class="red--text">OUTL</span>
                <span class="blue--text">=OUTLIER</span>
              </span>
              <span class="one-text-footer mx-5">
                <span>NA</span>
                <span class="blue--text">=DATA NOT AVAILABLE</span>
              </span>
            </v-col>
          </v-footer>
        </template>
      </v-card>
    </v-container>
  </v-container>
</template>



<script>
  import axios from "axios";
  import Papa from "papaparse";
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  // import renderPDF from 'chrome-headless-render-pdf';

  export default {
    data: () => ({
      //////////////////////////////

      tableHeader: [],
      tableContent: [],
      paramArrangement: ["DATETIME"],
      parameterDetail: [],
      paramStation: [],
      contentDetail: [],
      itemPerPage: 10,

      /////////////////////////////

      GlobalDataDaily: [],
      DataRenderingChart: [],
      loadingReport: false,
      showChart: false,
      showTitle: false,
      chartbutton: false,
      backbutton: false,
      check_list: [],

      tableH: [],
      tableCon: [],

      chartCon: [],

      pdfExc: [],

      pathModul: [
        {
          text: "Home",
          disabled: false,
          href: "/Dashboard",
        },
        {
          text: "Report - Daily",
          disabled: true,
          href: "/Report/DailyReport",
        },
      ],
      itemStation: [],
      // stn_List: ['CMS01', 'CMS02', 'CMS03', 'CMS04'],
      stn_List: ['All Station'],
      s_sel: "",
      dateFormat: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modalCalendar: false,
      menu2: false,
      showExportBtn: false,

      // Variable/Object for value filtering
      valStation: null,
      valDate: null,
      generalUser: false,
      // Title - Subtitle After Generate Data
      stationID: null,
      locationStation: "",
      stnSelect: '',

      // DataTable
      loadingTable: false,
      loadingText: "",

      // Header Table
      mainTheader: [],
      checkboxTheader: [],
      thead_dailyReport: [],
      mainTime: { category: "TIME" },
      subTime: {
        text: "Datetime",
        sortable: false,
        value: "datetime_a",
        divider: true,
        align: "center",
      },
      subSid: {
        text: "Station ID",
        sortable: false,
        value: "station_a",
        divider: true,
        align: "center",
      },
      subLoc: {
        text: "Location",
        sortable: false,
        value: "LOCATION",
        divider: true,
        align: "center",
      },
      si_do_sat: {
        reading: "SI_DO_SAT",
        acronym: "Sub-index Sat. DO",
        fullname: "Dissolved Oxygen (Saturation)",
        unit: "Index",
        text: "Dissolved Oxygen (Saturation) (Index)",
        value: "SI_DO_SAT",
        divider: true,
        align: "center",
        category: "DO",
      },
      si_ph: {
        reading: "SI_pH",
        acronym: "Sub-index pH",
        fullname: "pH",
        unit: "Index",
        text: "pH (Index)",
        value: "SI_pH",
        divider: true,
        align: "center",
        category: "pH",
      },
      si_tss: {
        reading: "SI_TSS",
        acronym: "Sub-index SS",
        fullname: "Total Suspended Solids",
        unit: "Index",
        text: "Total Suspended Solids (Index)",
        value: "SI_TSS",
        divider: true,
        align: "center",
        category: "TSS",
      },
      sortArraySubHeader: [],

      // Variable/Object for collection of checked parameter from header table
      arrayChecked: [],

      // Body Table
      tbody_dailyReport: [],
      exportTableParam: [],

      // Highchart formating generated
      chartOptions: {
        chart: {
          height: 600,
          // type: 'spline'
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },

        yAxis: {
          opposite: false,
          plotLines: [],
        },
        series: [],
        legend: {
          enabled: true,
        },
      },

      arrayDataChart: [],

      objPlotClass: [],

      plotLinesValue: [
        {
          param: "WQI",
          class1: 92.7,
          class2: 76.5,
          class3: 51.9,
          class4: 31.0,
          class5: 30.9,
          threshold: null,
        },
        {
          param: "DO_CON",
          class1: 7,
          class2: 5,
          class3: 3,
          class4: 1,
          class5: 1,
          threshold: null,
        },
        {
          param: "BOD",
          class1: 0.98,
          class2: 1,
          class3: 4,
          class4: 6,
          class5: 12,
          threshold: null,
        },
        {
          param: "COD",
          class1: 9.98,
          class2: 10,
          class3: 25,
          class4: 50,
          class5: 100,
          threshold: null,
        },
        {
          param: "NH3N",
          class1: 0.098,
          class2: 0.1,
          class3: 0.3,
          class4: 0.9,
          class5: 2.7,
          threshold: null,
        },
        {
          param: "TSS",
          class1: 24.98,
          class2: 25,
          class3: 50,
          class4: 150,
          class5: 300,
          threshold: null,
        },
        {
          param: "pH",
          class1: 7,
          class2: 6,
          class3: 5,
          class4: 4.98,
          threshold: null,
        },
      ],

      objClassLevel: [],
      station_info: [],
      ph_active: false,
      tss_active: false,
      do_active: false,

      inExportExcel: false,

      role: 0,
    }),

    methods: {
      convert12Hours(time) {
        var H = +time.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = H < 12 || H === 24 ? "AM" : "PM";
        time = h + time.substr(2, 3) + time.substr(5, 3) + " " + ampm;
        return time;
      },

      convertDateFormat(date) {
        var dd = date.split("-")[2];
        var mm = date.split("-")[1];
        var yyyy = date.split("-")[0];
        date = dd + "/" + mm + "/" + yyyy;
        return date;
      },

      selSta(a) {
        this.valStation = a;
      },

      sel_Date(a) {
        this.valDate = a;
      },

      // Anything need to load first refresh
      load() {
        this.getListStation();
      },

      // Generate list dropdown station dynamic
      getListStation() {
        axios
          .get(this.globalUrl + "bakaj/stations", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {

            if( this.role == 5){
              this.stn_List = [];
            }

            for (let i = 0; i < response.data.length; i++) {
              
              if( this.role == 5){

                if (response.data[i].stationId == "WQMS02" || response.data[i].stationId == "WQMS03" || response.data[i].stationId == "WQMS05") {
                  this.stn_List.push(
                    response.data[i].stationId + " - " + response.data[i].location
                  );

                  let obj = {
                    STATION_ID: response.data[i].stationId,
                    pH_ACTIVE: response.data[i].pH_ACTIVE,
                    TSS_ACTIVE: response.data[i].TSS_ACTIVE,
                    DO_CON_ACTIVE: response.data[i].DO_CON_ACTIVE,
                    DO_SAT_ACTIVE: response.data[i].DO_SAT_ACTIVE,
                  };

                  if(i == 1) { this.s_sel = response.data[i].stationId + " - " + response.data[i].location }

                  this.station_info.push(obj);

                }
              }
              else{
                this.stn_List.push(
                  response.data[i].stationId + " - " + response.data[i].location
                );

                let obj = {
                  STATION_ID: response.data[i].stationId,
                  pH_ACTIVE: response.data[i].pH_ACTIVE,
                  TSS_ACTIVE: response.data[i].TSS_ACTIVE,
                  DO_CON_ACTIVE: response.data[i].DO_CON_ACTIVE,
                  DO_SAT_ACTIVE: response.data[i].DO_SAT_ACTIVE,
                };

                this.station_info.push(obj);
              }
             
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },

      getDataDaily(station, tarikh, arrChecked) {
        // this.tbody_dailyReport = [];

        axios
          .get(
            this.globalUrl +
            "bakaj/daily?startdt=" +
            tarikh +
            "&stationid=" +
            station.replaceAll('All Station', 'all'),
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
              },
            }
          )
          .then((response) => {
            // Globalkan Data Daily Report
            this.DataRenderingChart.push(response.data);
            for (let i = 0; i < response.data.length; i++) {
              let keyData = Object.keys(response.data[0]);

              for (let x = 0; x < keyData.length; x++) {
                if (keyData[x].includes("FLAG")) {
                  if (
                    response.data[i][keyData[x]] < 0 ||
                    response.data[i][keyData[x]] == "NULL" ||
                    response.data[i][keyData[x]] == "null"
                  ) {
                    response.data[i][keyData[x]] = null;
                    this.GlobalDataDaily.push(response.data);
                  }
                  this.GlobalDataDaily.push(response.data);
                } else {
                  // if (response.data[i][keyData[x]] < 0 || response.data[i][keyData[x]] == null || response.data[i][keyData[x]] == "NULL" ||response.data[i][keyData[x]] == "null") {
                  //   response.data[i][keyData[x]] = "NA";
                  //   this.GlobalDataDaily.push(response.data);
                  // }
                  if (
                    response.data[i][keyData[x]] == null ||
                    response.data[i][keyData[x]] == "NULL" ||
                    response.data[i][keyData[x]] == "null"
                  ) {
                    response.data[i][keyData[x]] = "NA";
                    this.GlobalDataDaily.push(response.data);
                  } else {
                    if (
                      keyData[x] != "WQI" &&
                      keyData[x] != "SI_DO_SAT" &&
                      keyData[x] != "SI_BOD" &&
                      keyData[x] != "SI_COD" &&
                      keyData[x] != "SI_NH3N" &&
                      keyData[x] != "SI_TSS" &&
                      keyData[x] != "SI_pH"
                    ) {
                      if (typeof response.data[i][keyData[x]] == "number") {
                        response.data[i][keyData[x]] =
                          response.data[i][keyData[x]].toFixed(0);
                      }
                    }
                  }

                  if (
                    response.data[i].WQI < 0 ||
                    response.data[i].WQI == null ||
                    response.data[i].WQI == "NULL" ||
                    response.data[i].WQI == "null"
                  ) {
                    response.data[i].WQI = "NA";
                    this.GlobalDataDaily.push(response.data);
                  }
                }
              }
            }

            this.GlobalDataDaily.push(response.data);

            this.tbody_dailyReport = this.GlobalDataDaily[0];
            this.loadingReport = false;
            this.loadingTable = false;
            this.loadingText = "";

            // Get data to generate body table and title location table
            for (let i = 0; i < response.data.length; i++) {
              // this.tbody_dailyReport.push(response.data[i]);
              this.locationStation = response.data[0]["LOCATION"];
            }
          })
          .catch((error) => {
            this.loadingReport = false;
            this.loadingTable = false;
            console.log(error);
          });
      },

      getParamStation(station, ph_active, tss_active, do_active) {
        axios
          .get(this.globalUrl + "bakaj/params?stationid=" + station.replaceAll('All Station', 'all'), {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            for (let i in response.data) {
              if (response.data[i].value == "_4_4_DDT") {
                response.data[i].category = "4";
              }
            }

            // let html_sym = JSON.parse('{"entity": "&deg;"}');

            // let param_list =  [
            //   {
            //     "reading": "SI_DO_SAT",
            //     "acronym": "Sub-index Sat. DO",
            //     "fullname": "Dissolved Oxygen (Saturation)",
            //     "unit": "Index",
            //     "text": "Dissolved Oxygen (Saturation) (Index)",
            //     "value": "SI_DO_SAT",
            //     "divider": true,
            //     "align": "center",
            //     "category": "DO"
            //   },
            //   {
            //     "reading": "DO_SAT",
            //     "acronym": "DO Saturation",
            //     "fullname": "Dissolved Oxygen (Saturation)",
            //     "unit": "% Sat",
            //     "text": "Dissolved Oxygen (Saturation) (% Sat)",
            //     "value": "DO_SAT",
            //     "divider": true,
            //     "align": "center",
            //     "category": "DO"
            //   },
            //   {
            //     "reading": "DO_CON",
            //     "acronym": "DO Concentration",
            //     "fullname": "Dissolved Oxygen (Concentration)",
            //     "unit": "mg/L",
            //     "text": "Dissolved Oxygen (Concentration) (mg/L)",
            //     "value": "DO_CON",
            //     "divider": true,
            //     "align": "center",
            //     "category": "DO"
            //   },
            //   {
            //     "reading": "SI_pH",
            //     "acronym": "Sub-index pH",
            //     "fullname": "pH",
            //     "unit": "Index",
            //     "text": "pH (Index)",
            //     "value": "SI_pH",
            //     "divider": true,
            //     "align": "center",
            //     "category": "pH"
            //   },
            //   {
            //     "reading": "pH",
            //     "acronym": "pH",
            //     "fullname": "pH",
            //     "unit": null,
            //     "text": "pH ",
            //     "value": "pH",
            //     "divider": true,
            //     "align": "center",
            //     "category": "pH"
            //   },

            //   {
            //     "reading": "SI_TSS",
            //     "acronym": "Sub-index SS",
            //     "fullname": "Total Suspended Solids",
            //     "unit": "Index",
            //     "text": "Total Suspended Solids (Index)",
            //     "value": "SI_TSS",
            //     "divider": true,
            //     "align": "center",
            //     "category": "TSS"
            //   },
            //   {
            //     "reading": "TSS",
            //     "acronym": "TSS",
            //     "fullname": "Total Suspended Solids",
            //     "unit": "mg/L",
            //     "text": "Total Suspended Solids (mg/L)",
            //     "value": "TSS",
            //     "divider": true,
            //     "align": "center",
            //     "category": "TSS"
            //   },
            //   {
            //     "reading": "TEMPERATURE",
            //     "acronym": "Temp",
            //     "fullname": "Temperature",
            //     "unit": "°C",
            //     "text": "Temperature (℃)",
            //     "value": "TEMPERATURE",
            //     "divider": true,
            //     "align": "center",
            //     "category": "TEMPERATURE"
            //   },
            //   {
            //     "reading": "EC",
            //     "acronym": "EC",
            //     "fullname": "Electrical Conductivity",
            //     "unit": "µS/cm",
            //     "text": "Electrical Conductivity (µS/cm)",
            //     "value": "EC",
            //     "divider": true,
            //     "align": "center",
            //     "category": "EC"
            //   },
            //   {
            //     "reading": "SALINITY",
            //     "acronym": "Salinity",
            //     "fullname": "Salinity",
            //     "unit": "ppt",
            //     "text": "Salinity (ppt)",
            //     "value": "SALINITY",
            //     "divider": true,
            //     "align": "center",
            //     "category": "SALINITY"
            //   },
            //   {
            //     "reading": "TDS",
            //     "acronym": "TDS",
            //     "fullname": "Total Dissolved Solid",
            //     "unit": "mg/L",
            //     "text": "Total Dissolved Solid (mg/L)",
            //     "value": "TDS",
            //     "divider": true,
            //     "align": "center",
            //     "category": "TDS"
            //   },
            //   {
            //     "reading": "TURBIDITY",
            //     "acronym": "Turbidity",
            //     "fullname": "Turbidity",
            //     "unit": "NTU",
            //     "text": "Turbidity (NTU)",
            //     "value": "TURBIDITY",
            //     "divider": true,
            //     "align": "center",
            //     "category": "TURBIDITY"
            //   },
            //   {
            //     "reading": "NH4",
            //     "acronym": "NH4",
            //     "fullname": "Ammonium",
            //     "unit": "mg/L",
            //     "text": "Ammonium (mg/L)",
            //     "value": "NH4",
            //     "divider": true,
            //     "align": "center",
            //     "category": "NH4"
            //   },
            //   {
            //     "reading": "NO3",
            //     "acronym": "NO3",
            //     "fullname": "Nitrate",
            //     "unit": "mg/L",
            //     "text": "Nitrate (mg/L)",
            //     "value": "NO3",
            //     "divider": true,
            //     "align": "center",
            //     "category": "NO3"
            //   },
            //   {
            //     "reading": "OIL_IN_WATER",
            //     "acronym": "Oil in Water",
            //     "fullname": "Oil In Water",
            //     "unit": "mg/L",
            //     "text": "Oil In Water (mg/L)",
            //     "value": "OIL_IN_WATER",
            //     "divider": true,
            //     "align": "center",
            //     "category": "OIL"
            //   },
            //   {
            //     "reading": "PAH_HYDROCARBON",
            //     "acronym": "PAHs",
            //     "fullname": "Polycyclic Aromatic Hydrocarbons",
            //     "unit": "µg/l",
            //     "text": "Polycyclic Aromatic Hydrocarbons (µg/l)",
            //     "value": "PAH_HYDROCARBON",
            //     "divider": true,
            //     "align": "center",
            //     "category": "PAH"
            //   },
            //   {
            //     "reading": "WATER_LEVEL",
            //     "acronym": "Water Level",
            //     "fullname": "Water Level",
            //     "unit": "m",
            //     "text": "Water Level (m)",
            //     "value": "WATER_LEVEL",
            //     "divider": true,
            //     "align": "center",
            //     "category": "WATER"
            //   },
            //   {
            //     "reading": "VELOCITY",
            //     "acronym": "Velocity",
            //     "fullname": "Velocity",
            //     "unit": "m/s",
            //     "text": "Velocity (m/s)",
            //     "value": "VELOCITY",
            //     "divider": true,
            //     "align": "center",
            //     "category": "VELOCITY"
            //   },
            //   {
            //     "reading": "FLOWRATE",
            //     "acronym": "Flowrate",
            //     "fullname": "Flowrate",
            //     "unit": "m3/s",
            //     "text": "Flowrate (m3/s)",
            //     "value": "FLOWRATE",
            //     "divider": true,
            //     "align": "center",
            //     "category": "FLOWRATE"
            //   }
            //   // {
            //   //   "reading": "DEPTH",
            //   //   "acronym": "Depth",
            //   //   "fullname": "Depth",
            //   //   "unit": "m",
            //   //   "text": "Sonde Depth (m)",
            //   //   "value": "DEPTH",
            //   //   "divider": true,
            //   //   "align": "center",
            //   //   "category": "DEPTH"
            //   // },
            //   // {
            //   // "reading": "SOLAR_BATTERY",
            //   // "acronym": "Solar Battery",
            //   // "fullname": "Solar battery",
            //   // "unit": "V",
            //   // "text": "Solar battery (V)",
            //   // "value": "SOLAR_BATTERY",
            //   // "divider": true,
            //   // "align": "center",
            //   // "category": "SOLAR_BATTERY"
            //   // }
            // ]

            let param_list = response.data;

            let keys2 = ["category", "text", "value", "divider", "align"],
              result2 = param_list.map((o) =>
                Object.assign(...keys2.map((k) => ({ [k]: o[k] })))
              );

            this.tableH = result2;

            if (do_active == true) {
              var indexdo = 0;
              for (let i in result2) {
                if (result2[i].value === "DO_CON") {
                  indexdo = Number(i) - 1;
                }
              }
              this.tableH.splice(indexdo, 0, this.si_do_sat);
            }

            if (ph_active == true) {
              var indexph = 0;
              for (let i in result2) {
                if (result2[i].value === "pH") {
                  indexph = Number(i);
                }
              }
              this.tableH.splice(indexph, 0, this.si_ph);
            }

            if (tss_active == true) {
              var indextss = 0;
              for (let i in result2) {
                if (result2[i].value === "TSS") {
                  indextss = Number(i);
                }
              }
              this.tableH.splice(indextss, 0, this.si_tss);
            }

            // this.tableH.unshift(this.subSid);
            // this.tableH.unshift(this.subLoc);
            this.tableH.unshift(this.subTime);

            this.check_list = result2;
            // for (let i = 0; i < result2.length; i++) {
            //   this.checkboxTheader = result2.value;
            // }
          })
          .catch((error) => {
            console.log(error);
          });
      },

      // Generate Table
      GenerateTable() {
        this.showExportBtn = true;
        this.showChart = false;
        this.showTitle = true;
        this.loadingTable = true;
        this.loadingText = "Data Loading... Please wait";
        this.loadingReport = true;

        this.chartbutton = true;
        this.backbutton = false;

        // console.log(this.valStation)

        if(this.$store.getters.user.role == 5){
          if (this.valStation == null){
            this.valStation = "WQMS02 - Sg. Semanggar"
          }
        }
        // Keluarkan Details SubText
        // this.stationID = this.valStation.slice(0, 5);
        this.stationID = this.valStation.split(" - ")[0];

        // Unhide checkbox on header
        // document.querySelector(".subTitle").style.display = "unset";
        // let chk = document.querySelectorAll(".checkbox-header-dailyReport")
        // for (let i = 0; i < chk.length; i++) {
        //   chk[i].style.display = "contents";
        // }

        // Clear Body Table first after generate new datatable
        this.GlobalDataDaily = [];
        this.DataRenderingChart = [];
        this.mainTheader = [];
        this.checkboxTheader = [];
        this.thead_dailyReport = [];
        this.tbody_dailyReport = [];
        this.sortArraySubHeader = [];
        // this.arrayChecked = [];
        this.tableCon = [];

        this.$store.getters.user.idUserAccess === 3 ? this.generalUser == true : this.generalUser == false; 

        

        console.log(this.generalUser)
        console.log(this.$store.getters.user.idUserAccess)

        // Run/Generate Table from function load data API
        // this.getDataDaily(this.valStation.split(' - ')[0],this.dateFormat,this.arrayChecked);

        let stat = this.valStation;
        stat = stat.split(" - ");
        let stati = stat[0];

        this.locationStation = stat[1];
        this.stationID = stati;
        this.ph_active = false;
        this.tss_active = false;
        this.do_active = false;

        for (let i in this.station_info) {
          if (stati === this.station_info[i].STATION_ID) {
            if (this.station_info[i].pH_ACTIVE != null) {
              this.ph_active = true;
            }
            if (this.station_info[i].TSS_ACTIVE != null) {
              this.tss_active = true;
            }
            if (
              this.station_info[i].DO_CON_ACTIVE != null &&
              this.station_info[i].DO_SAT_ACTIVE != null
            ) {
              this.do_active = true;
            }
          }
        }

        // Generate Thead as Dynamic
        this.getParamStation(
          this.valStation.split(" - ")[0],
          this.ph_active,
          this.tss_active,
          this.do_active
        );

        



        axios
          .get(
            this.globalUrl +
            "bakaj/daily?startdt=" +
            this.dateFormat +
            "&stationid=" +
            stati.replaceAll('All Station', 'all'),
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
              },
            }
          )
          .then((response) => {
            // this.itemStation = [];

            let keyData = Object.keys(response.data[0]);
            let keyData_val = Object.keys(response.data[0]);
            let datas = response.data;

            console.log(datas)
            this.pdfExc = datas;

            for (let i in datas) {
              if (datas[i]["datetime_a"].includes("T")) {
                datas[i]["datetime_a"] =
                  this.convertDateFormat(datas[i]["datetime_a"].split("T")[0]) +
                  " " +
                  this.convert12Hours(datas[i]["datetime_a"].split("T")[1]);
              }

              if (datas[i]["DO_SAT"] != null) {
                datas[i]["DO_SAT"] = datas[i]["DO_SAT"].toFixed(2);
              } else {
                datas[i]["DO_SAT"] = datas[i]["DOSAT_FLAG"];
              }

              if (datas[i]["DO_CON"] != null) {
                datas[i]["DO_CON"] = datas[i]["DO_CON"].toFixed(2);
              } else {
                datas[i]["DO_CON"] = datas[i]["DOCON_FLAG"];
              }

              if (datas[i]["SI_DO_SAT"] != null) {
                datas[i]["SI_DO_SAT"] = datas[i]["SI_DO_SAT"].toFixed(0);
              } else {
                datas[i]["SI_DO_SAT"] = "NA";
              }

              if (datas[i]["pH"] != null) {
                datas[i]["pH"] = datas[i]["pH"].toFixed(2);
              } else {
                datas[i]["pH"] = datas[i]["pH_FLAG"];
              }

              if (datas[i]["SI_pH"] != null) {
                datas[i]["SI_pH"] = datas[i]["SI_pH"].toFixed(0);
              } else {
                datas[i]["SI_pH"] = "NA";
              }

              if (datas[i]["TSS"] != null) {
                datas[i]["TSS"] = datas[i]["TSS"].toFixed(1);
              } else {
                datas[i]["TSS"] = datas[i]["TSS_FLAG"];
              }

              if (datas[i]["SI_TSS"] != null) {
                datas[i]["SI_TSS"] = datas[i]["SI_TSS"].toFixed(0);
              } else {
                datas[i]["SI_TSS"] = "NA";
              }

              if (datas[i]["TEMPERATURE"] != null) {
                datas[i]["TEMPERATURE"] = datas[i]["TEMPERATURE"].toFixed(2);
              } else {
                datas[i]["TEMPERATURE"] = datas[i]["TEMP_FLAG"];
              }

              if (datas[i]["EC"] != null) {
                datas[i]["EC"] = datas[i]["EC"].toFixed(2);
              } else {
                datas[i]["EC"] = datas[i]["EC_FLAG"];
              }

              if (datas[i]["SALINITY"] != null) {
                datas[i]["SALINITY"] = datas[i]["SALINITY"].toFixed(2);
              } else {
                datas[i]["SALINITY"] = datas[i]["SALINITY_FLAG"];
              }

              if (datas[i]["TDS"] != null) {
                datas[i]["TDS"] = datas[i]["TDS"].toFixed(2);
              } else {
                datas[i]["TDS"] = datas[i]["TDS_FLAG"];
              }

              if (datas[i]["TURBIDITY"] != null) {
                datas[i]["TURBIDITY"] = datas[i]["TURBIDITY"].toFixed(1);
              } else {
                datas[i]["TURBIDITY"] = datas[i]["TURBIDITY_FLAG"];
              }

              if (datas[i]["NH4"] != null) {
                datas[i]["NH4"] = datas[i]["NH4"].toFixed(2);
              } else {
                datas[i]["NH4"] = datas[i]["NH4_FLAG"];
              }

              if (datas[i]["NO3"] != null) {
                datas[i]["NO3"] = datas[i]["NO3"].toFixed(2);
              } else {
                datas[i]["NO3"] = datas[i]["NO3_FLAG"];
              }

              if (datas[i]["OIL_IN_WATER"] != null) {
                datas[i]["OIL_IN_WATER"] = datas[i]["OIL_IN_WATER"].toFixed(2);
              } else {
                datas[i]["OIL_IN_WATER"] = datas[i]["OIL_IN_WATER_FLAG"];
              }

              if (datas[i]["PAH_HYDROCARBON"] != null) {
                datas[i]["PAH_HYDROCARBON"] =
                  datas[i]["PAH_HYDROCARBON"].toFixed(2);
              } else {
                datas[i]["PAH_HYDROCARBON"] = datas[i]["PAH_HYDROCARBON_FLAG"];
              }

              if (datas[i]["WATER_LEVEL"] != null) {
                datas[i]["WATER_LEVEL"] = datas[i]["WATER_LEVEL"].toFixed(2);
              } else {
                datas[i]["WATER_LEVEL"] = datas[i]["WATER_LEVEL_FLAG"];
              }

              if (datas[i]["VELOCITY"] != null) {
                datas[i]["VELOCITY"] = datas[i]["VELOCITY"].toFixed(2);
              } else {
                datas[i]["VELOCITY"] = datas[i]["VELOCITY_FLAG"];
              }

              if (datas[i]["FLOWRATE"] != null) {
                datas[i]["FLOWRATE"] = datas[i]["FLOWRATE"].toFixed(2);
              } else {
                datas[i]["FLOWRATE"] = datas[i]["FLOWRATE_FLAG"];
              }

              if (datas[i]["DEPTH"] != null) {
                datas[i]["DEPTH"] = datas[i]["DEPTH"].toFixed(2);
              } else {
                datas[i]["DEPTH"] = datas[i]["DEPTH_FLAG"];
              }

              if (datas[i]["BATTERY"] != null) {
                datas[i]["BATTERY"] = datas[i]["BATTERY"].toFixed(2);
              } else {
                datas[i]["BATTERY"] = datas[i]["BATTERY_FLAG"];
              }

              if (datas[i]["CONDUCTIVITY"] != null) {
                datas[i]["CONDUCTIVITY"] = datas[i]["CONDUCTIVITY"].toFixed(2);
              } else {
                datas[i]["CONDUCTIVITY"] = datas[i]["CONDUCTIVITY_FLAG"];
              }

              if (
                keyData[i] != "time" &&
                keyData[i] != "name" &&
                keyData[i] != "epochdate" &&
                keyData[i] != "WQI" &&
                keyData[i] != "WQI_CLASS" &&
                keyData[i] != "STATION_ID" &&
                keyData[i] != "DATETIME"
              ) {
                for (let x in keyData) {
                  if (keyData[x] == "datetime_a") {
                    keyData[x] = "DATETIME";
                  }

                  if (keyData[x] == "station_a") {
                    keyData[x] = "STATION ID";
                  }
                }

                let title = {
                  text: keyData[i],
                  value: keyData_val[i],
                };

                this.tableCon.push(datas[i]);
              }
            }

            this.loadingTable = false;
            this.loadingReport = false;
          })
          .catch((error) => {
            console.log(error);
          });

        this.getDataDaily(
          this.valStation.split(" - ")[0],
          this.dateFormat,
          this.arrayChecked
        );
      },

      // Customize background color for column table for result WQI status
      getColor(wqi) {
        if (wqi > 92.7) {
          return "#3486FA";
        } else if (wqi > 76.5 && wqi <= 92.7) {
          return "#7AE666";
        } else if (wqi >= 50 && wqi <= 76.5) {
          return "#FFFF10";
        } else if (wqi >= 31.0 && wqi <= 51.9) {
          return "#FFAB3D";
        } else if (wqi >= 1 && wqi <= 30.9) {
          return "#FB401D";
        } else {
          return "white";
        }
      },

      // Customize font color for column table for result WQI status
      getFontColor(wqi) {
        if (wqi > 92.7) {
          return "white--text";
        } else if (wqi > 76.5 && wqi <= 92.7) {
          return "black--text";
        } else if (wqi >= 50 && wqi <= 76.5) {
          return "black--text";
        } else if (wqi >= 31.0 && wqi <= 51.9) {
          return "white--text";
        } else if (wqi >= 1 && wqi <= 30.9) {
          return "white--text";
        } else {
          return "black--text";
        }
      },

      exportCSV() {
        // let headerExportCsv = [];
        // for (let i in this.tableH) {
        //   headerExportCsv.push(this.tableH[i]["value"]);
        // }

        let listKeyHeader = [];
        for(let i in this.tableHeader){
          listKeyHeader.push(this.tableHeader[i].value)
        }

        let keys = listKeyHeader,
          resultAfterFilter = this.contentDetail.map((o) =>
            Object.assign(...keys.map((k) => ({ [k]: o[k] })))
          );
        let dataTbodyOnly = resultAfterFilter;

        let listKeyHeaderFullname = []
        for(let i in dataTbodyOnly){
          for(let x in listKeyHeader){
            for(let z in this.parameterDetail){
              if(listKeyHeader[x] == this.parameterDetail[z].value){
                listKeyHeaderFullname.push(this.parameterDetail[z].text);
                dataTbodyOnly[i][this.parameterDetail[z].text] = dataTbodyOnly[i][listKeyHeader[x]]
                dataTbodyOnly[i]["Datetime"] = this.convertDatetimeDisplay(dataTbodyOnly[i]["DATETIME"])
              }
            }
          }
        }
        let lastKeys = listKeyHeaderFullname.filter((item,
        index) => listKeyHeaderFullname.indexOf(item) === index);
        lastKeys.unshift("Datetime")

        let keyssss = lastKeys,
          lastResults = dataTbodyOnly.map((o) =>
            Object.assign(...keyssss.map((k) => ({ [k]: o[k] })))
          );
        let dataToExportCSV = lastResults;

        console.log(dataToExportCSV);


        const BOM = "\uFEFF";
        let blob = new Blob([BOM + Papa.unparse(dataToExportCSV)], {
          type: "text/csv;charset=utf-8;",
        });
        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);

        link.setAttribute("href", url);
        link.setAttribute("download", "Daily Report.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },

      exportExcel() { 

        this.itemPerPage = -1;

        // Define your style class template.

        let headerName;

        if (this.stationID == 'All Station'){

          headerName = "<tr = 'border:none !important' ><td colspan ='24' style = 'text-align: center; border:none !important; font-weight: bold'>Water Quality Status For " + this.locationStation + " ( " + this.stationID + ") " + this.convertDateFormat(this.dateFormat) + "</td></tr>";

        } else {
          headerName = "<tr = 'border:none !important' ><td colspan ='26' style = 'text-align: center; border:none !important; font-weight: bold'>Water Quality Status For " + this.locationStation + " ( " + this.stationID + ") " + this.convertDateFormat(this.dateFormat) + "</td></tr>";
        }

    
        var style = "<style> th, td { border: 10px solid;}</style>";

        var uri = "data:application/vnd.ms-excel;base64,",
          template =
            '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' +
            style +
            "</head><body><table>" +
            headerName +
            "{table}</table></body></html>",
          base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)));
          },
          format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
              return c[p];
            });
          };

        this.inExportExcel = true;

        setTimeout(() => {
          let table = document.querySelectorAll(".v-data-table__wrapper ");

          // console.log(table[0].innerHTML)

          let tablei = table[0].innerHTML;
          // if (tablei.includes("℃")){
          tablei = tablei.replaceAll("°C", "&#176;C").replaceAll("µ", "&#181;");


          let ctx = { worksheet: "Daily Report" || "Worksheet", table: tablei };

          let a = document.createElement("a");
          a.href = uri + base64(format(template, ctx));
          a.download = "Daily Report.xls";
          //triggering the function
          a.click();

          this.inExportExcel = false;

          this.itemPerPage = 10;

        }, 1000)

        // let myInterval = setInterval(() => {
        //     a.click();

        //     let myInterval2 = setInterval(() => {
        //         document.getElementsByClassName("header-colspan")[0].style.display = "";
        //         clearInterval(myInterval2);
        //     }, 5000);
        //     clearInterval(myInterval);
        // }, 5000);
      },

      // Get value checked from header checkbox
      checkedValue: function (e, value) {
        //console.log(this.arrayChecked);
      },

      // Generate Data Chart
      generateChart() {
        this.showChart = true;
        this.showTitle = false;

        this.chartbutton = false;
        this.backbutton = true;

        this.chartCon = [];

        let stat = this.valStation;
        stat = stat.split(" - ");
        let stati = stat[0];

        this.locationStation = stat[1];
        this.stationID = stati;

        axios
          .get(
            this.globalUrl +
            "bakaj/daily?startdt=" +
            this.dateFormat +
            "&stationid=" +
            stati.replaceAll('All Station', 'all'),
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < response.data.length; i++) {
              this.tableCon.push(response.data[i]);
            }
          })
          .catch((error) => {
            console.log(error);
          });

        let arrStringChecked = [];
        for (let i = 0; i < this.arrayChecked.length; i++) {
          arrStringChecked.push(this.arrayChecked[i]);
        }

        this.loadDataChart(this.tableCon, arrStringChecked);
      },

      // Rendering Data Chart
      loadDataChart(data, checked) {
        // Clear first chart before generate another new chart
        this.chartOptions.series = [];
        let thresholdValue = null;
        this.chartOptions.yAxis.plotLines = [];

        // Create object data to put in series chart formating
        // if (checked.length == 1) {

        let objPlotClass = [];

        for (let i = 0; i < checked.length; i++) {
          let obj_name;

          if (checked[i] == "SI_pH") {
            obj_name = "pH (Index)";
          } else if (checked[i] == "SI_DO_SAT") {
            obj_name = "Dissolved Oxygen (Saturation) (Index)";
          } else if (checked[i] == "DO_SAT") {
            obj_name = "Dissolved Oxygen (Saturation) (% Sat)";
          } else if (checked[i] == "SI_TSS") {
            obj_name = "Total Suspended Solids (Index)";
          } else if (checked[i] == "TSS") {
            obj_name = "Total Suspended Solids (mg/L)";
          } else if (checked[i] == "DO_CON") {
            obj_name = "Dissolved Oxygen (Concentration) (mg/L)";
          } else if (checked[i] == "TEMPERATURE") {
            obj_name = "Temperature (°C)";
          } else if (checked[i] == "EC") {
            obj_name = "Electrical Conductivity (µS/cm)";
          } else if (checked[i] == "CONDUCTIVITY") {
            obj_name = "Conductivity (µS/cm)";
          } else if (checked[i] == "SALINITY") {
            obj_name = "Salinity (ppt)";
          } else if (checked[i] == "TDS") {
            obj_name = "Total Dissolved Solid (mg/L)";
          } else if (checked[i] == "TURBIDITY") {
            obj_name = "Turbidity (NTU)";
          } else if (checked[i] == "NH4") {
            obj_name = "Ammonium (mg/L)";
          } else if (checked[i] == "NO3") {
            obj_name = "Nitrate (mg/L)";
          } else if (checked[i] == "OIL_IN_WATER") {
            obj_name = "Oil In Water (mg/L)";
          } else if (checked[i] == "PAH_HYDROCARBON") {
            obj_name = "Polycyclic Aromatic Hydrocarbons (µg/l)";
          } else if (checked[i] == "WATER_LEVEL") {
            obj_name = "Water Level (m)";
          } else if (checked[i] == "VELOCITY") {
            obj_name = "Velocity (m/s)";
          } else if (checked[i] == "FLOWRATE") {
            obj_name = "Flowrate (m3/s)";
          } else if (checked[i] == "DEPTH") {
            obj_name = "Sonde Depth (m)";
          } else if (checked[i] == "SOLAR_BATTERY") {
            obj_name = "Solar battery (V)";
          } else {
            obj_name = checked[i];
          }

          let obj = {
            name: obj_name,
            data: [],
          };

          data.forEach(function (res, k) {
            obj.data.push([res.epochdate, Number(res[checked[i]])]);
          });

          this.chartOptions.series.push(obj);
        }

        // Subtitle Chart

        if (this.valStation == 'All Station') {
          this.chartOptions.subtitle.text = "Water Quality Status For All Station " + this.convertDateFormat(this.dateFormat);
        } else {
          this.chartOptions.subtitle.text = "Water Quality Status For " + this.locationStation + " (" + this.valStation.split(" - ")[0] + ") " + this.convertDateFormat(this.dateFormat);
        }
      },

      // Back to Table Form from Chart Form
      backToTable() {
        this.showChart = false;
        this.showTitle = true;
        this.chartbutton = true;
        this.backbutton = false;
        this.chartOptions.series = [];
        this.GenerateTable();
      },

      ////////////////////////////

      getParameterList() {
        this.showExportBtn = true;
        this.showChart = false;
        this.showTitle = true;
        this.loadingTable = true;
        this.loadingText = "Data Loading... Please wait";
        this.loadingReport = true;

        this.chartbutton = true;
        this.backbutton = false;

        this.tableHeader = [];
        this.tableContent = [];
        this.paramArrangement = ["DATETIME"];
        this.parameterDetail = [];
        this.paramStation = [];

        let stat = this.valStation;
        stat = stat.split(" - ");
        let stati = stat[0];

        axios
          .get(this.globalUrl + "/bakaj/params?stationid=" + stati.replaceAll('All Station', 'all'), {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            this.paramStation = response.data;
            this.getParamArrangement();
          })
          .catch((error) => {
            console.log(error);
          });
      },

      getParamArrangement() {

        if (this.s_sel == "All Station") {
          this.paramArrangement.push("STATION_ID", "LOCATION");
        }


        console.log("paramss", this.paramStation);

        for (let i = 0; i < this.paramStation.length; i++) {
          if (this.paramStation[i].reading == "DO_SAT") {
            if (this.paramStation[i + 1].reading == "DO_CON") {
              this.paramArrangement.push("SI_DO_SAT");
              this.paramArrangement.push("DO_SAT");
              this.paramArrangement.push("DO_CON");
              i = i + 1; //sbb nak skip do_con
            } else {
              this.paramArrangement.push("DO_SAT");
            }
          } else if (this.paramStation[i].reading == "pH") {
            this.paramArrangement.push("SI_pH");
            this.paramArrangement.push("pH");
          } else if (this.paramStation[i].reading == "TSS") {
            this.paramArrangement.push("SI_TSS");
            this.paramArrangement.push("TSS");
          } else {
            this.paramArrangement.push(this.paramStation[i].reading);
          }
        }

        // console.log("arrs", this.paramArrangement);
        axios
          .get(this.globalUrl + "/bakaj/params", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            this.parameterDetail = response.data;
            this.getTableHeader();

          })
          .catch((error) => {
            console.log(error);
          });
      },

      getTableHeader() {


        let obj = {
          reading: "DATETIME",
          fullname: "DATETIME",
          value: "DATETIME",
          text: "Datetime",
        }

        this.tableHeader.push(obj);

        if (this.s_sel == "All Station") {
          obj = {
            reading: "STATION_ID",
            fullname: "STATION_ID",
            value: "STATION_ID",
            text: "Station ID",
          }

          this.tableHeader.push(obj);

          obj = {
            reading: "LOCATION",
            fullname: "LOCATION",
            value: "LOCATION",
            text: "Location",
          }

          this.tableHeader.push(obj);
        }


        for (let j = 0; j < this.paramArrangement.length; j++) {
          for (let i = 0; i < this.parameterDetail.length; i++) {
            if (this.paramArrangement[j] == this.parameterDetail[i].reading) {

              console.log("paramIYHS", this.parameterDetail[i]);
              this.parameterDetail[i]["width"] = "180px"
              this.tableHeader.push(this.parameterDetail[i]);
            }
          }
        }

        console.log("paramDeets", this.tableHeader);
        this.getContentDetail();
      },

      getContentDetail() {
        let stat = this.valStation;
        stat = stat.split(" - ");
        let stati = stat[0];

        axios
          .get(
            this.globalUrl +
            "bakaj/daily?startdt=" +
            this.dateFormat +
            "&stationid=" +
            stati.replaceAll('All Station', 'all'),
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
              },
            }
          )
          .then((response) => {
            this.contentDetail = response.data;
            this.chartbutton = true;
            this.loadingTable = false;

            this.getTableContent();
          })
          .catch((error) => {
            console.log(error);
          });
      },


      getTableContent() {
        for (let j = 0; j < this.contentDetail.length; j++) {

          for (let i = 0; i < this.paramArrangement.length; i++) {
            let obj;

            if (
              this.paramArrangement[i] == "DATETIME" || this.paramArrangement[i] == "STATION_ID" || this.paramArrangement[i] == "LOCATION" ||
              this.paramArrangement[i].includes("SI_") == true
            ) {
              obj = {
                parameter: this.paramArrangement[i],
                value: this.contentDetail[j][this.paramArrangement[i]],
              };

            } else {
              //  console.log("dssss", this.contentDetail[j][this.paramArrangement[i]]);
              obj = {
                parameter: this.paramArrangement[i],
                value:
                  this.contentDetail[j][this.paramArrangement[i]] != null
                    ? this.contentDetail[j][this.paramArrangement[i]].toFixed(2)
                    : this.contentDetail[j][this.paramArrangement[i] + "_FLAG"],
                class:
                  this.contentDetail[j][this.paramArrangement[i]] != null
                    ? ""
                    : "flag-color",
              };
            }

            // console.log("jidjdijdi", obj);
            this.tableContent.push(obj);
          }

          this.loadingReport = false;
        }

        // for (let i = 0; i < this.paramArrangement.length ; i++){
        //   for ( let j = 0 ; j < this.contentDetail.length ; j++){
        //     if(this.paramArrangement[i] == )
        //     if(this.paramArrangement[i] == "DATETIME" || this.paramArrangement[i].includes("SI_") == true ) {

        //       let obj = {
        //       parameter : this.paramArrangement[i],
        //       value: this.contentDetail[j][this.paramArrangement[i]]
        //       }

        //       this.tableContent.push(obj)
        //     }
        //     else {
        //       //  console.log("dssss", this.contentDetail[j][this.paramArrangement[i]]);
        //       let obj = {
        //         parameter : this.paramArrangement[i],
        //         value: this.contentDetail[j][this.paramArrangement[i]] != null ? this.contentDetail[j][this.paramArrangement[i]].toFixed(2) : this.contentDetail[j][this.paramArrangement[i] + "_FLAG"],
        //         class: this.contentDetail[j][this.paramArrangement[i]] != null ? '' : "flag-color"
        //       }

        //       this.tableContent.push(obj)
        //     }

        //   }
        // }

        // console.log("tablecon", this.tableContent);
      },

      /////////////////////////
    },

    mounted() {

      this.load();
      this.role = this.$store.getters.user.role;
    },
  };
</script>



<style lang="scss">
  @import "~scss/main";

  // Nuzul
  .tableDailyReport > .v-data-table__wrapper > table > .v-data-table-header > tr > th{
    border-bottom: 0px !important;
    position: sticky !important;
    top: 48px !important;
    z-index: 2 !important;
  }

  // Korang

  /* Section Path File/Modul */
  .pathModul {
    padding-bottom: 0px !important;
  }

  .pathModul>li:nth-child(3)>a {
    color: black !important;
  }

  /* .v-breadcrumbs__item {
    color: black !important;
  } */

  /* Section Filtering Input/Select/Button */
  .container-filtering {
    padding: 0px;
  }

  .v-text-field.v-text-field--solo .v-input__control,
  .btn-generate .v-btn .v-btn--contained {
    min-height: 10px;
    margin-top: 10px;
  }

  .btn-download-table {
    margin-right: 8px;
  }

  /* Section Result Generated Table Report */
  .container-result-generated {
    background: white;
    //padding: 5px 0px;
    // height: calc(100vh - 250px);
  }

  .th-sub {
    background: white !important;
  }

  .subTitle {
    text-align: center !important;
    background: white;
    // color: #1976D2 !important;
    display: block;
  }

  .header-colspan>tr:nth-child(1)>th {
    text-align: center !important;
    border-right: 0.1px solid white;
    border-left: 0.1px solid white;
    background-image: linear-gradient(to bottom, #1272d5, #004892) !important;
    color: white !important;
  }

  .header-colspan>tr:nth-child(2)>th {
    text-align: center !important;
    border: 0px solid white;
    color: black !important;
    height: 30px !important;
    padding: 20px !important;
  }

  .v-data-table-header>tr:nth-child(1)>th {
    border: 0px solid white !important;
  }

  /* .v-data-table-header th {
    white-space: nowrap;
  } */

  .checkbox-header-dailyReport>th:nth-child(1)>input {
    display: none;
  }

  .checkbox-header-dailyReport {
    display: none;
  }

  .tableDailyReport {
    margin: 10px 0px;
    border: 0.1px solid #e0e0e0;
  }

  // .tableDailyReport > div:nth-child(1) > table > thead:nth-child(3) > tr > th{
  //   background: white !important;
  // }

  .tableDailyReport>.v-data-table__wrapper>table>thead>tr>th {
    border-right: 0.1px solid white !important;
    border-left: 0.1px solid white !important;
  }

  .tableDailyReport>.v-data-table__wrapper>table>.v-data-table-header>tr>th,
  .tableDailyReport>.v-data-table__wrapper>table>thead:nth-child(2)>tr:nth-child(2)>th,
  .tableDailyReport>.v-data-table__wrapper>table>thead:nth-child(3)>tr>th {
    background: #004892 !important;
    color: white !important;
    border-right: 0.1px solid white !important;
    border-left: 0.1px solid white !important;
  }

  .tableDailyReport>.v-data-table__wrapper>table>tbody>tr>th {
    white-space: nowrap;
  }

  .container-result-generated {
    text-align: center;
  }

  .vChip-text {
    color: black !important;
  }

  .datePickerDiv>>>input {
    border: none;
    background: transparent;
  }

  .v-input--selection-controls__input {
    margin: 0px;
  }

  /* Custom vue default */
  .tableDailyReport>.v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: auto;
    max-height: 480px;
  }

  .v-data-table__empty-wrapper>td {
    text-align: left !important;
  }

  /* Section Result Generated Chart Report */
  .form-chart {
    display: none;
  }

  .highcharts-credits {
    display: none !important;
  }

  /* Footer Report */
  .footer-report {
    background: white;
  }
</style>